// page-top

$(function(){
  var topBtn=$('#page-top');
  topBtn.hide();
  $(window).scroll(function(){
    if($(this).scrollTop()>80){
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function(){
    $('body,html').animate({
    scrollTop: 0},500);
    return false;
  });
});


// home header

$(function(){
  var header = $('.header');
  $(window).scroll(function(){
    if($(this).scrollTop()>300){
      header.addClass('scrolled');
    } else {
      header.removeClass('scrolled');
    }
  });
});


// header-nav

$('.header-nav__item').click(function () {
  $(this).toggleClass('opened');
  $(this).find('a').toggleClass('opened');
  $(this).find('.header-nav__sub-list').toggleClass('opened');
});


// sp login

$('.header-login__btn').click(function () {
  $('.header-sp-menu').removeClass('opened');
  $('.header-sp-menu-box').removeClass('opened');
  if ($('.header-login__btn').hasClass('opened')) {
    $(this).removeClass('opened');
    $('.header-login__box').removeClass('opened');
    $('.overlay').removeClass('showed');
  } else {
    $(this).addClass('opened');
    $('.header-login__box').addClass('opened');
    $('.overlay').addClass('showed');
  }
});


// sp menu

$('.header-sp-menu').click(function () {
  $('.header-login__btn').removeClass('opened');
  $('.header-login__box').removeClass('opened');
  if ($('.header-sp-menu').hasClass('opened')) {
    $(this).removeClass('opened');
    $('.header-sp-menu-box').removeClass('opened');
    $('.overlay').removeClass('showed');
  } else {
    $(this).addClass('opened');
    $('.header-sp-menu-box').addClass('opened');
    $('.overlay').addClass('showed');
  }
});

$('.overlay').click(function () {
  $('.header-login__btn').removeClass('opened');
  $('.header-login__box').removeClass('opened');
  $('.header-sp-menu').removeClass('opened');
  $('.header-sp-menu-box').removeClass('opened');
  $('.overlay').removeClass('showed');
});
